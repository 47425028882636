const googleAnalyticsTrackingId = "G-SG8B6V5FB6";

export function onClientEntry () {
    resolveUserTrackingConsent();
}

export function onRouteUpdate () {
    resolveUserTrackingConsent();
}

function resolveUserTrackingConsent () {
    let hasGivenConsent = window.localStorage.getItem("cookie-tracking-consented");

    if (hasGivenConsent === "true") {
        window[`ga-disable-${googleAnalyticsTrackingId}`] = false;
    } else {
        window[`ga-disable-${googleAnalyticsTrackingId}`] = true;
    }
}




