module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wordpress-on-azure.azurewebsites.net/graphql","develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"type":{"_all":{"limit":500},"actionMonitorAction":{"exclude":true},"actionMonitorActionBy":{"exclude":true},"actionMonitorActions":{"exclude":true},"allCPTDocumentation":{"exclude":true},"allSettings":{"exclude":true},"cPTDocumentation":{"exclude":true},"cPTDocumentationBy":{"exclude":true},"categories":{"exclude":true},"category":{"exclude":true},"comment":{"exclude":true},"comments":{"exclude":true},"discussionSettings":{"exclude":true},"plugin":{"exclude":true},"plugins":{"exclude":true},"registeredScripts":{"exclude":true},"registeredStylesheets":{"exclude":true},"revisions":{"exclude":true},"tag":{"exclude":true},"tags":{"exclude":true},"taxonomies":{"exclude":true},"taxonomy":{"exclude":true},"taxonomyCategories":{"exclude":true},"taxonomyCategory":{"exclude":true},"terms":{"exclude":true},"theme":{"exclude":true},"themes":{"exclude":true},"user":{"exclude":true},"userRole":{"exclude":true},"userRoles":{"exclude":true},"users":{"exclude":true},"viewer":{"exclude":true},"wpGatsby":{"exclude":true},"wpGatsbyCompatibility":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":10,"requestConcurrency":1,"previewRequestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wordpress-on-azure.azurewebsites.net/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"retail-suite-website","short_name":"retail-suite","start_url":"/","background_color":"#58DEA3","theme_color":"#58DEA3","display":"minimal-ui","icon":"src/assets/images/retailsuite_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0f1d51f3a6876ff8b114a0672f8f7784"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-SG8B6V5FB6"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
